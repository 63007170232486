<template>
    <div class="v-videos">
        <div class="v-videos-header">
            <v-breadcrumb :index="index" :child="child"></v-breadcrumb>
        </div>
        <div class="v-videos-body">
            <div class="v-videos-search">
                <Input
                    :placeholder="'搜索' | language"
                    search
                    v-model="keywords"
                    @on-change="handleChange"
                    class="videos-search-input"
                />
            </div>
        </div>
        <div class="v-videos-list">
            <Row class="section-list-item">
                <Col span="24">
                    <div class="videos-articles">
                        <a-list
                            class="v-card-list"
                            item-layout="vertical"
                            :data-source="searchList"
                            :pagination="pagination"
                        >
                            <a-list-item
                                class="v-card-list-item"
                                slot="renderItem"
                                slot-scope="item, index"
                            >
                                <a-list-item-meta>
                                    <div
                                        slot="description"
                                        class="v-card-list-item-meta box-shadow"
                                    >
                                        <!-- <a-avatar class="v-card-list-avatar" shape="square" :size="126" :src="item.cover"
                                        @click="gotoDetail(item)" /> -->
                                        <div
                                            class="v-card-list-avatar point-cursor"
                                        >
                                            <img
                                                :src="item.cover"
                                                @click="gotoDetail(item)"
                                            />
                                        </div>
                                        <template
                                            v-if="item.courseType == 'coCourse'"
                                        >
                                            <div class="v-card-list-desc">
                                                <div
                                                    class="v-card-list-desc-item"
                                                >
                                                    <span
                                                        class="v-card-list-desc-item-label"
                                                        @click="
                                                            gotoDetail(item)
                                                        "
                                                    >
                                                        {{ item.title }}
                                                    </span>
                                                </div>
                                                <div
                                                    class="v-card-list-desc-item"
                                                >
                                                    <span
                                                        class="v-card-list-desc-item-label"
                                                    >
                                                        视频数量:
                                                    </span>
                                                    <span
                                                        class="v-card-list-desc-item-label"
                                                    >
                                                        {{ item.num }}
                                                    </span>
                                                </div>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="v-card-list-desc">
                                                <div
                                                    class="v-card-list-desc-item"
                                                >
                                                    <span
                                                        class="v-card-list-desc-item-label blod-font"
                                                        @click="
                                                            gotoDetail(item)
                                                        "
                                                    >
                                                        {{ item.title }}
                                                    </span>
                                                </div>
                                                <div
                                                    class="v-card-list-desc-item"
                                                >
                                                    <span
                                                        class="v-card-list-desc-item-label"
                                                    >
                                                        观看次数:
                                                    </span>
                                                    <span
                                                        class="v-card-list-desc-item-label"
                                                    >
                                                        {{ item.nums }}
                                                    </span>
                                                </div>
                                                <div
                                                    class="v-card-list-desc-item"
                                                >
                                                    <span
                                                        class="v-card-list-desc-item-label"
                                                    >
                                                        创建时间:
                                                    </span>
                                                    <span
                                                        class="v-card-list-desc-item-label"
                                                    >
                                                        {{ item.writeAt }}
                                                    </span>
                                                </div>
                                                <div
                                                    class="v-card-list-desc-item  v-card-list-desc-item-padding"
                                                >
                                                    <span
                                                        class="v-card-list-desc-item-label"
                                                    >
                                                        视频简介:
                                                    </span>
                                                    <span
                                                        class="v-card-list-desc-item-clamp  clamp-5"
                                                    >
                                                        {{ item.desc }}
                                                    </span>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </a-list-item-meta>
                            </a-list-item>
                        </a-list>
                    </div>
                </Col>
            </Row>
        </div>
    </div>
</template>

<script>
import { MethodsUtil } from "../../assets/util/method.js";
import VBreadcrumb from "../../compontents/base/VBreadcrumb.vue";
import { httpURL } from "../../assets/util/http";
import { mapState } from "vuex";
import qs from "query-string";
import { debounce } from "throttle-debounce";
import utils from "./utils";
export default {
    name: "VideosSearch",
    components: { VBreadcrumb },
    data() {
        const pageSize = 10;
        this.debounceChange = debounce(500, this.fetchData);
        const keywords = this.$route.query.keywords;
        return {
            index: "",
            child: "",
            keywords: keywords,
            searchForm: {
                pageSize,
                pageNum: 0,
                year: "",
                packId: "",
                keywords: keywords,
                sort: "-createdAt",
            },
            pagination: {
                onChange: (page) => {
                    this.searchForm.pageNum = page;
                    const keywords = this.searchForm.keywords;
                    this.debounceChange(keywords);
                },
                total: 0,
                pageSize,
            },

            searchList: [],
            data2: [],
        };
    },
    computed: {
        ...mapState(["admin"]),
    },
    // watch: {
    //     keywords(newVal) {
    //         this.searchForm.keywords = newVal;
    //         this.debounceChange(newVal);
    //     },
    // },
    created() {
       // this.handleChange(this.keywords);
    },
    mounted() {
        let { index, child } = MethodsUtil.getRouteIndexAndChild(this.$route);
        this.index = index;
        this.child = child;
    },
    methods: {
        handleChange(evt) {
            const keywords = (evt && evt.target && evt.target.value) || "";
            this.$router.push({
                path: `/videos/search`,
                query: { keywords: keywords },
            });
            this.$nextTick(() => {
                this.debounceChange(keywords);
            });
        },
        gotoDetail(rowData) {
            const { type, id, coursePack, auth } = rowData;
            if (auth == true && this.admin == null) {
                // this.$router.push('/common/login');
                window.open(`${httpURL}/user/auth`);
                return;
            }
            this.$router.push({
                path: `/videos/${type}/${id}`,
                query: { cId: coursePack && coursePack._id },
            });
        },
        formatList(list) {
            return list.map((item) => {
                const {
                    _id,
                    courseType,
                    cover,
                    title,
                    viewCount,
                    introduce,
                    name,
                    courseAmount,
                    coursePack,
                } = item;
                return {
                    id: _id,
                    src: cover.src,
                    type: courseType || "coCourse",
                    title: title,
                    num: viewCount,
                    desc: introduce || "",
                    coursePack,
                    org: item,
                };
            });
        },
        async fetchData(keywords) {
            // GET /api/information/courseSearch?pageSize=10&pageNum=0&year=2022&packId=合集id&keywords=关键词&sort=-createdAt
            const baseUrl = "/information/courseSearch";
            const params = qs.stringify({
                ...this.searchForm,
                keywords,
            });
            let resp = await this.$http.get(`${baseUrl}/?${params}`);
            if (resp) {
                const { result, count } = resp;
                this.pagination.total = count;
                this.searchList = utils.getList(result); // this.formatList(result);
            }
        },
    },
};
</script>
<style lang="less">
.section-list-item {
    margin-bottom: 32px;
}

.videos-search-input {
    width: 60%;
    margin: auto;
}

.videos-tab-panel {
    min-height: 300px;

    .ivu-row-flex {
        justify-content: flex-start;
        margin-bottom: 16px;
    }
}

.videos-more-btns {
    position: realtive;
}

.videos-more-btn {
    position: absolute;
    right: 16px;
    top: 0;
}

.videos-card {
    margin: 0 10px;

    .videos-card-cover {
        overflow: hidden;
        height: 116px;

        & > img {
            display: block;
            width: 100%;
            opacity: 1;
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
            transition: opacity 0.5s, -webkit-transform 0.5s;
            transition: opacity 0.5s, transform 0.5s;
            transition: opacity 0.5s, transform 0.5s, -webkit-transform 0.5s;
            vertical-align: middle;
            border-style: none;
            border-radius: 2px 2px 0 0;
        }

        &:hover > img {
            opacity: 1;
            -webkit-transform: scale(1.2);
            transform: scale(1.2);
        }
    }

    .videos-card-body {
        padding: 8px;
    }

    .videos-owner {
        font-weight: bold;
        font-size: 12px;
        text-align: left;
        margin-top: 16px;
    }

    .videos-desc {
        text-align: left;
        margin-top: 4px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-size: 12px;
        font-weight: 500;
        height: 40px;
        word-break: break-all;
    }

    .videos-actions {
        padding: 4px 0 0;
        text-align: left;
        font-size: 12px;
    }
}

.videos-card:hover {
    box-shadow: 0 0 8px;
}

.videos-articles-title {
    overflow: hidden;
    text-align: left;
    margin-bottom: 16px;

    .videos-articles-label {
        font-size: 16px;
        font-weight: bold;
    }

    .videos-articles-more-btn {
        float: right;
        margin-right: 16px;
    }
}

.videos-articles {
    .articles-list-item {
        margin-bottom: 16px;
        text-align: left;
        height: 170px;
        overflow: hidden;
    }

    .articles-desc {
        .articles-total-classes {
            color: #f6706d;
            margin-bottom: 8px;
        }

        .articles-desc-list {
            margin-bottom: 8px;
        }

        .articles-desc-label {
        }
    }

    .videos-articles-avatar {
        margin-left: 16px;
        cursor: pointer;

        & > img {
            object-fit: fill;
            opacity: 1;
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
            transition: opacity 0.5s, -webkit-transform 0.5s;
            transition: opacity 0.5s, transform 0.5s;
            transition: opacity 0.5s, transform 0.5s, -webkit-transform 0.5s;
        }

        &:hover > img {
            opacity: 1;
            -webkit-transform: scale(1.2);
            transform: scale(1.2);
        }
    }
}

.videos-ranking-pl80 {
    margin-left: 80px;
}

.videos-ranking-title {
    font-size: 16px;
    margin-bottom: 16px;
    font-weight: bold;
    text-align: left;
}

.videos-ranking {
    text-align: left;

    .first-ranking {
        background: #1479d7;
    }

    .two-ranking {
        background: #4f9be1;
    }

    .three-ranking {
        background: #8abceb;
    }

    .videos-ranking-msg {
        display: inline-block;
        height: 45px;
        line-height: 45px;
    }
}

@media only screen and (max-width: 1200px) {
    .videos-card {
        .videos-card-cover {
            height: 199px;
        }
    }
}
</style>
