<template>
    <div class="v-videos">
        <div class="v-videos-header">
            <v-breadcrumb :index="index" :child="child"></v-breadcrumb>
        </div>
        <div class="v-videos-body">
            <div class="v-videos-search">
                <Input :placeholder="'搜索' | language" search v-model="keywords" @on-focus="handleChange"
                    class="videos-search-input" />
            </div>
            <component v-bind:is="current" :keywords="keywords"></component>
        </div>
    </div>
</template>

<script>
import CommonSearchBar from "../../compontents/information/CommonETalkBar.vue";
import { MethodsUtil } from "../../assets/util/method.js";
import VBreadcrumb from "../../compontents/base/VBreadcrumb.vue";
import { mapState } from 'vuex';
import VideosList from "./VideosList.vue";
import VideosSearch from "./VideosSearch.vue";
import { debounce } from "throttle-debounce";

export default {
    name: "videos",
    components: { CommonSearchBar, VBreadcrumb, VideosSearch, VideosList },
    data() {
        // this.debounceChange = debounce(200, this.handleChange);
        return {
            current: VideosList.name,
            index: "",
            child: "",
            keywords: "",
        };
    },
    mounted() {
        let { index, child } = MethodsUtil.getRouteIndexAndChild(this.$route);
        this.index = index;
        this.child = child;
    },
    watch: {
        keywords(val) {
            if (val == "") {
                this.current = VideosList.name;
            }
        },
    },
    computed: {
        ...mapState(["admin"])
    },
    methods: {
        handleChange(val) {
            this.$router.push({
                path: `/videos/search`,
                query: { keywords: this.keywords },
            });
        },
    },
};
</script>

<style lang="less">
.videos {
    .videos-search-container {
        position: realtive;
        margin-bottom: 16px;
    }

    .videos-body {
        width: 90%;
        margin: 48px auto 0;
    }
}
</style>
