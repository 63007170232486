<template>
    <div class="select-container">
        <div class="select-list">
            <div class="select-item">
                <div class="select-item-title">{{ "状态" | language }}</div>
                <Select
                    v-model="tag"
                    class="my-select"
                    @on-change="handleChange"
                    v-if="!showProject"
                >
                    <Option
                        v-for="item in tagList"
                        :value="item.value"
                        :key="item.value"
                        >{{ item.label | language }}</Option
                    >
                </Select>
                <Select
                    v-model="tag"
                    style="width: 165px"
                    @on-change="handleChange"
                    v-else
                >
                    <Option
                        v-for="(item, index) in projectTags"
                        :value="item"
                        :key="index"
                        >{{ item | language }}</Option
                    >
                </Select>
            </div>
            <div class="select-item">
                <div class="select-item-title">{{ "年份" | language }}</div>
                <Select
                    v-model="year"
                    class="my-select"
                    @on-change="handleChange"
                >
                    <Option
                        v-for="item in yearList"
                        :value="item.value"
                        :key="item.value"
                        >{{ item.label | language }}</Option
                    >
                </Select>
            </div>
        </div>
        <div class="search-container">
            <Input
                :placeholder="'搜索' | language"
                search
                v-model="keyword"
                @keyup.enter.native="handleChange"
                @on-search="handleChange"
                class="my-search-input"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        showProject: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            year: "",
            tag: "",
            keyword: "",
            tagList: [
                {
                    label: "全部",
                    value: "all",
                },
                {
                    label: "可预约",
                    value: "reserve",
                },
                {
                    label: "已完成",
                    value: "done",
                },
            ],
            yearList: [],
            projectTags: [],
        };
    },
    async mounted() {
        this.getYear();
        await this.fetchTag();
        if (this.showProject) {
            this.tag = this.projectTags[0];
        } else {
            this.tag = this.tagList[0].value;
        }
        this.year = this.yearList[0].value;
        this.$emit("change", {
            year: this.year,
            tag: this.tag,
            keyword: this.keyword,
        });
    },
    methods: {
        handleChange() {
            this.$emit("change", {
                year: this.year,
                tag: this.tag,
                keyword: this.keyword,
            });
        },
        async fetchTag() {
            let res = await this.$http.get("/achievement/tags");
            this.projectTags = res;
        },
        //获取最近10年
        getYear() {
            let date = new Date();
            let year = date.getFullYear();
            this.yearList.push({ label: "全部", value: "all" });
            for (let i = 0; i < 10; i++) {
                this.yearList.push({ label: year - i, value: year - i });
            }
        },
    },
};
</script>

<style scoped>
.ivu-select-selection {
    border-radius: 19px;
}
.ivu-input {
    border-radius: 19px;
    padding-left: 25px;
}
</style>
<style lang="less" scoped>
.select-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .my-select {
        width: 165px;
    }
    .select-list {
        display: flex;
        align-items: flex-start;
        .select-item {
            display: flex;
            align-items: center;
            .select-item-title {
                margin-right: 10px;
                opacity: 0.6;
                font-size: 16px;
                color: #000;
                letter-spacing: 0;
                line-height: 29px;
                flex-shrink: 0;
                margin-right: 20px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
    .search-container {
        .search-title {
            margin-bottom: 10px;
            height: 22px;
            font-size: 16px;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.85);
            line-height: 22px;
            text-align: left;
        }
        .my-search-input {
            width: auto;
        }
    }
}
@media screen and (max-width: 960px) {
    .select-container {
        flex-wrap: wrap;
        .select-list {
            width: 100%;
            justify-content: space-between;
            .select-item {
                .select-item-title {
                    font-size: 14px;
                    margin-right: 5px;
                }
            }
        }
        .my-select {
            width: 30vw;
        }
        .search-container {
            margin-top: 10px;
            width: 100%;
            .my-search-input {
                width: 100%;
            }
        }
    }
}
</style>
