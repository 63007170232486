import { render, staticRenderFns } from "./CommonETalkBar.vue?vue&type=template&id=13b53d90&scoped=true&"
import script from "./CommonETalkBar.vue?vue&type=script&lang=js&"
export * from "./CommonETalkBar.vue?vue&type=script&lang=js&"
import style0 from "./CommonETalkBar.vue?vue&type=style&index=0&id=13b53d90&scoped=true&lang=css&"
import style1 from "./CommonETalkBar.vue?vue&type=style&index=1&id=13b53d90&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13b53d90",
  null
  
)

export default component.exports