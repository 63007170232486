<template>
    <div class="videos-container">
        <index-text-header></index-text-header>
        <router-view />
    </div>
</template>
<script>
import IndexTextHeader from "../../compontents/index/IndexTestHeader";
export default {
    components: { IndexTextHeader },
};
</script>
<style lang="less">
.v-videos {
    .v-videos-header {}

    .v-videos-body,
    .v-videos-list {
        width: 90%;
        margin: 48px auto 0;
    }

    .v-videos-search {
        position: realtive;
        margin-bottom: 16px;
    }

    .v-videos-list {}
}

.v-card-list {
    margin: 0 10px;

    .box-shadow {
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.14);

        &:hover {
            opacity: 0.8;
            box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.6);

            img {
                transform: scale(1.2);
            }
        }
    }

    .box-shadow-no-scale {
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.14);

        &:hover {
            opacity: 0.8;
            box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.6);
        }
    }

    .point-cursor {
        cursor: pointer;
    }

    .shadow-normal {
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.14);
    }

    .v-card-list-item-meta {
        overflow: hidden;
    }

    .v-card-list-item {
        margin-bottom: 16px;
        text-align: left;

        .ant-list-item-meta {
            display: block;
        }
    }

    .v-card-list-avatar {
        float: left;
        margin-right: 16px;
        width: 150px;
        height: 150px;
        margin-left: 8px;
        margin-top: 8px;
        margin-bottom: 8px;
        position: relative;
        overflow: hidden;

        &>img {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            opacity: 1;
            transform: scale(1);
            transition: opacity 0.5s, transform 0.5s;
        }
    }

    .v-card-list-cover {
        box-sizing: border-box;
        position: relative;
        padding: 0 100% 65% 0;
        overflow: hidden;

        .v-card-img-label {
            position: absolute;
            left: 0;
            bottom: 0;
            padding: 0 6px;
            background: rgba(20, 121, 215, 0.5);
            color: #fff;
            font-size: 12px;
        }

        &>img {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            opacity: 1;
            transform: scale(1);
            transition: opacity 0.5s, transform 0.5s;
        }
    }

    .v-card-list-desc {
        display: block;
        overflow: hidden;
        padding: 4px;
    }

    .v-card-list-desc-item {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        font-family: '宋体', Arial, Helvetica, sans-serif;
        color: #000;
        font-size: 12px;
    }

    .v-card-list-desc-item-label {
        float: left;
        margin-right: 8px;
        font-family: '宋体', Arial, Helvetica, sans-serif;
        font-weight: 600;
        width: 60px;
    }

    .v-card-list-desc-item-value {
        white-space: normal;
    }

    .v-card-list-desc-item-clamp {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        height: 44px;
    }
    .v-card-list-desc-item-padding{
        padding: 0 20px 10px 0;
    }

    .clamp-5 {
        -webkit-line-clamp: 5;
        height: auto;
    }

    .v-right {
        float: right;
    }

    .blod-font {
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
    }
}
</style>
