<template>
    <div class="videos-list v-videos-list">
        <div class="section-list-item">
            <Tabs :animated="false" v-model="activeTabName">
                <div class="videos-more-btns">
                    <Button class="videos-more-btn" @click="handleMoreBtn('videoCourse')">
                        更多 <Icon type="chevron-right"></Icon>
                    </Button>
                </div>
                <Tab-pane class="videos-tab-panel" name="newList" label="最新发布">
                    <Row type="flex">
                        <a-list class="v-card-list" size="large" :data-source="listMap"
                            :grid="{ gutter: 10, md: 2, lg: 6 }">
                            <a-list-item slot="renderItem" slot-scope="item, index" class="v-card-list-item">
                                <a-list-item-meta>
                                    <div slot="description" class="v-card-list-item-meta box-shadow">
                                        <div class="v-card-list-cover">
                                            <img slot="cover" alt="example" :src="item.cover" class="point-cursor"
                                                @click="gotoDetail(item)" />
                                            <span class="v-card-img-label" v-if="item.auth">校内用户</span>
                                            <span class="v-card-img-label" v-else>免验证</span>
                                        </div>
                                        <div class="v-card-list-desc">
                                            <div class="v-card-list-desc-item point-cursor" @click="gotoDetail(item)">
                                                <span class="v-card-list-desc-item-clamp">
                                                    {{ `${item.user}-${item.title}` }}
                                                </span>
                                            </div>
                                            <div class="v-card-list-desc-item">
                                                <span class="v-card-list-desc-item-label">
                                                    <Icon type="ios-clock"></Icon> {{ item.writeAt }}
                                                </span>
                                                <span class="v-card-list-desc-item-label v-right">
                                                    <Icon type="logo-youtube"></Icon> {{ item.nums || 0 }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </a-list-item-meta>
                            </a-list-item>
                        </a-list>
                    </Row>
                </Tab-pane>
            </Tabs>
        </div>
        <Row class="section-list-item">
            <Col span="16">
            <div class="videos-articles-title">
                <span class="videos-articles-label">专栏合集</span>
                <Button class="videos-articles-more-btn" @click="handleMoreBtn('coCourse')">
                    更多 <Icon type="chevron-right"></Icon>
                </Button>
            </div>
            <div class="videos-articles">
                <a-list class="v-card-list" :data-source="articleList" :grid="{
                        gutter: 16,
                        xs: 1,
                        sm: 1,
                        md: 1,
                        lg: 1,
                        xl: 1,
                        xxl: 1,
                    }">
                    <a-list-item class="v-card-list-item" slot="renderItem" slot-scope="item, index">
                        <a-list-item-meta>
                            <div slot="description" class="v-card-list-item-meta box-shadow">
                                <div class="v-card-list-avatar point-cursor">
                                    <img :src="item.cover" @click="gotoDetail(item)" />
                                </div>
                                <!-- <a-avatar class="v-card-list-avatar point-cursor" shape="square" :size="150"
                                    :src="item.cover" @click="gotoDetail(item)" /> -->
                                <div class="v-card-list-desc">
                                    <div class="v-card-list-desc-item">
                                        <span class="v-card-list-desc-item-label point-cursor blod-font"
                                            @click="gotoDetail(item)">
                                            {{ item.title }}
                                        </span>
                                    </div>
                                    <div class="v-card-list-desc-item">
                                        <span class="v-card-list-desc-item-label">
                                            视频总数:
                                        </span>
                                        <span class="v-card-list-desc-item-value">
                                            {{ item.nums }}
                                        </span>
                                    </div>
                                    <div class="v-card-list-desc-item">
                                        <span class="v-card-list-desc-item-label">修改时间：</span>
                                        <span class="v-card-list-desc-item-value">{{ item.writeAt }}</span>
                                    </div>
                                    <div class="v-card-list-desc-item">
                                        <span class="v-card-list-desc-item-label w70">
                                           描&nbsp;&nbsp述:
                                        </span>
                                        <span class="v-card-list-desc-item-clamp v-card-list-desc-item-padding  clamp-5">
                                            {{ item.desc }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </a-list-item-meta>
                    </a-list-item>
                </a-list>
            </div>
            </Col>
            <Col span="8">
            <div class="videos-ranking-pl80">
                <div class="videos-ranking-title">课程点击排行</div>
                <a-list class="videos-ranking v-card-list" item-layout="horizontal" :data-source="rankingList">
                    <a-list-item class="v-card-list-item" slot="renderItem" slot-scope="item, index">
                        <a-list-item-meta>
                            <div slot="description" class="v-card-list-item-meta">
                                <a-avatar :class="item.cls" class="v-card-list-avatar point-cursor" shape="square"
                                    :size="45" @click="gotoDetail(item)">
                                    {{ index + 1 }}
                                </a-avatar>
                                <div class="v-card-list-desc">
                                    <div class="v-card-list-desc-item break-label point-cursor" @click="gotoDetail(item)">
                                        <span class="v-card-list-desc-item-clamp">
                                            {{ item.title }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </a-list-item-meta>
                    </a-list-item>
                </a-list>
            </div>
            </Col>
        </Row>
    </div>
</template>

<script>
import { DayUtil } from "@/assets/util/day.js";
import { httpURL } from "../../assets/util/http";
import { mapState } from 'vuex';
import utils from "./utils"
import qs from "query-string";
export default {
    name: "VideosList",
    data() {
        return {
            activeTabName: "newList",
            listMap: [],
            articleList: [],
            rankingList: [],
        };
    },
    created() {
        this.fetchList();
    },
    computed: {
        ...mapState(["admin"])
    },
    methods: {
        handleMoreBtn(type) {
            this.$router.push({
                path: `/videos/more/${type}`,
                query: {},
            });
        },
        gotoDetail(rowData) {
            const { courseType = "coCourse", id, coursePack, auth } = rowData;
            if (auth == true && this.admin == null) {
                // this.$router.push('/common/login');
                window.open(`${httpURL}/user/auth`);
                return;
            }
            this.$router.push({
                path: `/videos/${courseType}/${id}`,
                query: { cId: coursePack && coursePack._id },
            });
        },
        formatListMap(list) {
            const result = [];
            for (let i = 0; i < list.length; i++) {
                const {
                    title,
                    viewCount,
                    introduce,
                    videoUrl,
                    courseType,
                    teacher,
                    updatedAt,
                    writeAt,
                    cover: { src },
                    _id,
                } = list[i];
                result.push({
                    id: _id,
                    cover: src,
                    desc: title,
                    user: teacher,
                    days: DayUtil.passedDays(updatedAt),
                    nums: `${viewCount} 次`,
                    url: videoUrl,
                    extral: list[i],
                });
            }

            return result;
        },
        formatList(list) {
            const result = [];
            for (let i = 0; i < list.length; i++) {
                const {
                    _id,
                    title,
                    introduce,
                    courseType,
                    videoUrl,
                    teacher,
                    name,
                    courseAmount,
                    createdAt,
                    remark,
                    cover: { src },
                } = list[i];
                result.push({
                    id: _id,
                    title: name,
                    remark,
                    desc: courseAmount,
                    avatar: src,
                    createdAt: DayUtil.fmtDate(createdAt),
                    extral: list[i],
                    courseType: "coCourse",
                });
            }
            return result;
        },
        formatRankings(list) {
            const result = [];
            const clsMap = {
                0: "first-ranking",
                1: "two-ranking",
                2: "three-ranking",
            };
            for (let i = 0; i < list.length; i++) {
                const { title, _id, courseType } = list[i];
                result.push({
                    title,
                    cls: clsMap[i],
                    id: _id,
                    extral: list[i],
                    courseType,
                });
            }
            return result;
        },
        async fetchNewList(pageSize = 12, pageNum = 0) {
            // /api/information/course?pageSize=10&pageNum=0&courseType=videoCourse&sort=-createdAt
            // /api/information/course?pageSize=10&pageNum=0&courseType=videoCourse&sort=-writeAt,-updatedAt
            const baseUrl = "/information/course";
            const newList = qs.stringify({
                pageSize,
                pageNum,
                courseType: "videoCourse",
                sort: "-createdAt",
            });
            // 最新发布
            const newListUrl = `${baseUrl}?${newList}`;
            let res = await this.$http.get(newListUrl);
            if (res.result && res.result.length) {
                this.listMap = utils.getList(res.result);
                // this.listMap = this.formatListMap(res.result);
            }
        },
        async fetchArticles(pageSize = 10, pageNum = 0) {
            // /api/information/coursePack?pageSize=10&pageNum=0&year=2021&keywords=xx
            // /api/information/coursePack?pageSize=10&pageNum=0&year=2021&keywords=xx
            const baseUrl = "/information/coursePack";
            const articles = qs.stringify({
                pageSize: 12,
                pageNum: 0,
                year: "",
                keywords: "",
            });
            const articlesUrl = `${baseUrl}?${articles}`;
            let res = await this.$http.get(articlesUrl);
            if (res.result && res.result.length) {
                this.articleList = utils.getList(res.result);//this.formatList(res.result);
            }
        },
        async fetchRanking(pageSize = 10, pageNum = 0) {
            // /api/information/course?pageSize=10&pageNum=0&courseType=videoCourse&sort=-viewCount,-createdAt
            // /api/information/course?pageSize=10&pageNum=0&courseType=videoCourse&sort=-viewCount,-writeAt,-updatedAt
            const baseUrl = "/information/course";
            const rankingList = qs.stringify({
                pageSize,
                pageNum,
                courseType: "videoCourse",
                sort: "-viewCount,-writeAt,-updatedAt",
            });
            const rankingtUrl = `${baseUrl}?${rankingList}`;
            let res = await this.$http.get(rankingtUrl);
            if (res.result && res.result.length) {
                this.rankingList = utils.getList(res.result); //this.formatRankings(res.result);
            }
        },
        async fetchList() {
            this.fetchNewList();
            this.fetchArticles();
            this.fetchRanking();
        },
    },
};
</script>

<style lang="less">
.w70 {
    width: 70px;
}

.break-label {
    word-break: break-all;
    white-space: normal;
}

.section-list-item {
    margin-bottom: 32px;
}

.videos-search-input {
    width: 60%;
    margin: auto;
}

.videos-tab-panel {
    min-height: 300px;

    .ivu-row-flex {
        flex: none;
        display: block;
    }
}

.videos-more-btns {
    position: realtive;
}

.videos-more-btn {
    position: absolute;
    right: 16px;
    top: 0;
}

.videos-card {
    margin: 0 10px;

    .videos-card-cover {
        overflow: hidden;
        cursor: pointer;
        height: 116px;

        &>img {
            display: block;
            width: 100%;
            opacity: 1;
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
            transition: opacity 0.5s, -webkit-transform 0.5s;
            transition: opacity 0.5s, transform 0.5s;
            transition: opacity 0.5s, transform 0.5s, -webkit-transform 0.5s;
            vertical-align: middle;
            border-style: none;
            border-radius: 2px 2px 0 0;
        }

        &:hover>img {
            opacity: 1;
            -webkit-transform: scale(1.2);
            transform: scale(1.2);
        }
    }

    .videos-card-body {
        padding: 8px;
    }

    .videos-owner {
        font-weight: bold;
        font-size: 12px;
        text-align: left;
        margin-top: 8px;
        height: 18px;
    }

    .videos-desc {
        text-align: left;
        margin-top: 4px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-size: 12px;
        font-weight: 500;
        cursor: pointer;
        height: 40px;
        word-break: break-all;
    }

    .videos-actions {
        padding: 4px 0 0;
        text-align: left;
        font-size: 12px;

        .videos-days {
            float: right;
        }
    }
}

.videos-card:hover {
    box-shadow: 0 0 8px;
}

.videos-articles-title {
    overflow: hidden;
    text-align: left;
    margin-bottom: 16px;

    .videos-articles-label {
        font-size: 16px;
        font-weight: bold;
    }

    .videos-articles-more-btn {
        float: right;
        margin-right: 16px;
    }
}

.videos-articles {
    .articles-list-item {
        margin-bottom: 16px;
        text-align: left;
        height: 170px;
        overflow: hidden;
    }

    .articles-desc {
        margin-top: 16px;

        .articles-desc-title {
            font-size: 14px;
            font-weight: 600;
            cursor: pointer;
        }

        .articles-total-classes {
            margin-bottom: 8px;
        }

        .articles-desc-list {
            margin-bottom: 8px;
        }

        .articles-desc-label {}

        .articles-desc-remark {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            word-break: break-all;
            overflow: hidden;
        }
    }

    .videos-articles-avatar {
        margin-top: 16px;
        margin-left: 16px;
        cursor: pointer;

        &>img {
            object-fit: fill;
            opacity: 1;
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
            transition: opacity 0.5s, -webkit-transform 0.5s;
            transition: opacity 0.5s, transform 0.5s;
            transition: opacity 0.5s, transform 0.5s, -webkit-transform 0.5s;
        }

        &:hover>img {
            opacity: 1;
            -webkit-transform: scale(1.2);
            transform: scale(1.2);
        }
    }
}

.videos-ranking-pl80 {
    margin-left: 80px;
}

.videos-ranking-title {
    font-size: 16px;
    margin-bottom: 16px;
    font-weight: bold;
    text-align: left;
}

.videos-ranking {
    text-align: left;

    .first-ranking {
        background: #1479d7;
    }

    .two-ranking {
        background: #4f9be1;
    }

    .three-ranking {
        background: #8abceb;
    }

    .videos-ranking-msg {
        display: inline-block;
        height: 45px;
        line-height: 45px;
    }
}

@media only screen and (max-width: 1200px) {
    .videos-card {
        .videos-card-cover {
            height: 199px;
        }
    }
}
</style>
